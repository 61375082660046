<template>
  <v-container
    fluid
    tag="section"
  >
      <material-card
        color="primary"
        icon="mdi-sale"
      >
        <template #title>
          Додати акцію
        </template>

        <ButtonComeBack class="ml-3 mb-3" />
        
        <v-col cols="12" lg="6">
          <v-form lazy-validation v-model="isValidForm" ref="form">
            <v-row>
              <!--Проекти-->
              <v-col cols="12">
                <v-select
                  v-model="action.projects" 
                  label="Проекти" 
                  chips 
                  multiple 
                  cache-items
                  return-object
                  item-text="name"
                  item-value="id"
                  :items="projectList" 
                  required
                  :rules="[v => !!v.length || errorRequired]"
                  prepend-icon="mdi-format-list-bulleted-type">
                </v-select>
              </v-col>

              <!--Тип акції-->
              <v-col cols="12">
                <v-select
                  return-object
                  item-text="name"
                  item-value="id"
                  v-model="action.type"
                  :items="actionTypeList"
                  label="Тип акції"
                  clearable
                  required
                  :rules="[v => !!v || errorRequired]"
                ></v-select>
              </v-col>

              <!--Механіка-->
              <v-col cols="12">
                <v-select
                  return-object
                  item-text="name"
                  item-value="id"
                  v-model="action.mechanic"
                  :items="actionMechanicList"
                  label="Механіка"
                  clearable
                  required
                  :rules="[v => !!v || errorRequired]"
                ></v-select>
              </v-col>

              <!--date_from-->
              <v-col cols="12" md="6">
                <v-menu
                  ref="menu"
                  v-model="action.isShowDatePickerFrom"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="action.date_from"
                      label="Дата з"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="[v => !!v || errorRequired, 
                              !(action.date_from > action.date_to) || errorDate]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="action.date_from"
                    no-title
                    @input="action.isShowDatePickerFrom = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!--date_to-->
              <v-col cols="12" md="6">
                <v-menu
                  ref="menu"
                  v-model="action.isShowDatePickerTo"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="action.date_to"
                      label="Дата по"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="[v => !!v || errorRequired, 
                              !(action.date_from > action.date_to) || errorDate]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="action.date_to"
                    no-title
                    @input="action.isShowDatePickerTo = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!--Табы-->
            <v-col cols="12">
              <v-tabs v-model="tab">
                <v-tab v-for="local in localization" 
                  :key="local.lang_key">
                  {{ local.name }}
                </v-tab>
              </v-tabs>
            </v-col>

            <!--Содержымое табов-->
            <v-col cols="12">
              <v-tabs-items v-model="tab">
                  <v-tab-item 
                    v-for="(item, index) in action.langs" 
                    :key="item.lang_key" 
                    :eager="true">
                    <template>
                      <!--Назва-->
                      <v-text-field
                        label="Назва"
                        v-model="item.name"
                        required
                        :rules="[v => !!v || errorRequired]"
                      />
                    </template>
                  </v-tab-item>
              </v-tabs-items>
            </v-col>
            
            <!--Кнопки-->
            <v-col cols="12" class="text-right">
              <v-btn 
                color="primary" 
                min-width="150" 
                @click="sendForm()">
                Зберегти
              </v-btn>
            </v-col>
            <Preloader v-if="isSending" />
          </v-form>
        </v-col>

      </material-card>
  </v-container>
</template>

<script>
import { HTTP } from '@/http-common';
import { mapGetters, mapMutations, mapActions } from 'vuex';
 
export default {
  name: 'PageActionAdd',
  data() {
    return {
      // Акция
      action: {
        langs: [],
      },
      // Активный таб
      tab: 0,
      isValidForm: true,
      actionMechanicList: [],
      actionTypeList: [],
      projectList: [],
      errorRequired: `Це поле обов'язкове`,
      errorDate: 'Дата "з" не може бути більшою ніж "по"',
      isSending: false,
    }
  },
  computed: {
    ...mapGetters(['snackbar', 'localization'])
  },
  mounted() {
    this.init();
  },

  methods: {
    ...mapMutations(['setSnackbar']),
    ...mapActions(['getLocalization']),
    // Подготовка данных
    async init() {
      await this.getLocalization();
      await this.getActionMechanicList();
      await this.getActionTypeList();
      await this.getProjectList();

      this.action.langs = this.localization.map((el) => {
        return { 
          lang_key: el.lang_key,
          lang_name: el.name,
          name: '',
          preview: '',
          text: '',
        };
      });
    },

    // Создать список ошибок (ошибки надо показать в попапе - потому что табы)
    createListErrors() {
      let errors = '';
      for(let item of this.action.langs) {
        if (!item.name) {
          errors += `Помилка в табi "${item.lang_name}"</br>`;
        }
      }
      return errors;
    },

    // Отправка формы
    async sendForm() {
      this.errorDate = '';

      if (!this.$refs.form.validate()) {
        let errors = this.createListErrors();
        if (errors) {
          this.setSnackbar({ type: 'error', text: errors });
        };
        return;
      };

      this.isSending = true;

      try {
        let { projects, type, mechanic, langs, date_from, date_to }  = this.action;
        let projectsData = projects.map((el) => {
          return { 
            project_id: el.id, 
            name: el.name 
          };
        });

        let { data } = await HTTP.post("/create-action", {
          mechanic_id: mechanic.id,
          mechanic: mechanic.name,
          action_type_id: type.id,
          action_type: type.name,
          projects: projectsData,
          langs, 
          date_from, 
          date_to,
        });
        this.setSnackbar({ type: 'success', text: 'Успiшно збережено' });
        this.$router.push(`/actions/${data.content.id}/`);
      } catch(error) {
        console.warn(error);
        this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
        this.isSending = false;
      }
    },

    // Получить механики акций
    async getActionMechanicList() {
      try {
        let { data } = await HTTP.post('/get-list-action-mechanic', { 
          lang_key: 'ua',
        });
        this.actionMechanicList = data.content.mechanic;
      } catch(error) {
        console.warn(error);
      }
    },

    // Получить типы акций
    async getActionTypeList() {
      try {
        let { data } = await HTTP.post('/get-list-action-type', { 
          lang_key: 'ua', 
          enabled: 1,
        });
        this.actionTypeList = data.content.type;
      } catch(error) {
        console.warn(error);
      }
    },

    // Получить список проектов
    async getProjectList() {
      try {
        let { data } = await HTTP.post('/get-projects');
        this.projectList = data.content;
      } catch(error) {
        console.warn(error);
      }
    },
  },
}
</script>

<style scoped lang="sass">
.form-action-add
  position: relative
</style>
